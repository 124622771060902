export const getCode = (value) => {
    let code = '';
    if (!value) {
        return code;
    }
    value = value.toString();
    for (let i = 0; i < 7-value.length; i++) {
        code += '0';
    }
    code += value;

    return code;
}

export const getDetailUrl = (id) => {
    return '/service/detail/'+btoa(encodeURI(id));
}