import gql from "graphql-tag/src";

export const SERVICE_WORK_LIST = gql`
query GetServiceWorkList($first: Int, $after: String){
  services(first: $first, after: $after) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        id
        _id
        code
        clientName
        clientPhone
        createdAt
        statusLabel
        statusStyle
      }
    }
  }
}
`;

export const CREATE_SERVICE_WORK = gql`
mutation CreateServiceWork(
  $clientName: String!, 
  $clientPhone: String!, 
  $clientAddress: String, 
  $clientEmail: String, 
  $productName: String!,
  $productSeries: String!,
  $productDescription: String!,
  $problemDescription: String!,
  $resolveTerm: String!,
  $isInWarranty: Boolean
) {
  createCreateServiceWork(input: {
    clientName: $clientName, 
    clientPhone: $clientPhone, 
    address: $clientAddress, 
    clientEmail: $clientEmail, 
    productName: $productName,
    productSeries: $productSeries,
    productDescription: $productDescription,
    problemDescription: $problemDescription,
    resolveTerm: $resolveTerm,
    isInWarranty: $isInWarranty
  }) {
    createServiceWork {
      response
    }
  }
}  
`;

export const SERVICE_WORK_DETAIL = gql`
query ServiceDetail ($id: ID!) {
  service(id: $id) {
    id
    _id
    clientName
    clientPhone
    code
    clientAddress
    clientEmail
    clientAddress
    productName
    productSeries
    productDescription
    problemDescription
    finishedOn
    warranty
    resolveTermDate
    resolveTermDateString
    statusLabel
    statusStyle
    status
    price
    loginCode
    priceModel {
      totalPrice
      price
      tax
      productOutcomes
      profit
    }
    investmentPrice
    options {
      statusOptions
      companyTechnicians
      formFields
      formFieldLabels
    }
    items {
      _id
      description
      createdAt
      status
      price
      boughtPrice
      unit
      technician {
        name
        id
        _id
      }
      actionLabel
      priceModel {
        totalPrice
        price
        tax
        unitPrice
        unity {
          unit
          unitLabel
        }
      }
    }
  }
  meUser {
    _id
    userRole {
      roles,
    }
  }
}  
`;

export const CREATE_SERVICE_WORK_ITEM = gql`
mutation CreateServiceWorkItem(
  $serviceId: String! 
  $status: Int! 
  $technician: String!
  $description: String
  $price: Float
  $boughtPrice: Float
  $unity: Int
) {
  createCreateServiceWorkItem(input: {
    serviceId: $serviceId
    status: $status
    technician: $technician
    description: $description
    price: $price
    boughtPrice: $boughtPrice
    unity: $unity
  }) {
    createServiceWorkItem {
      response
    }
  }
}
`;

export const EDIT_SERVICE_WORK = gql`
mutation EditServiceWork(
  $id: String!,
  $clientName: String!, 
  $clientPhone: String!, 
  $clientAddress: String, 
  $clientEmail: String, 
  $productName: String!,
  $productSeries: String!,
  $productDescription: String!,
  $problemDescription: String!,
  $resolveTerm: String!,
  $isInWarranty: Boolean
) {
  createEditServiceWork(input: {
    serviceId: $id,
    clientName: $clientName, 
    clientPhone: $clientPhone, 
    address: $clientAddress, 
    clientEmail: $clientEmail, 
    productName: $productName,
    productSeries: $productSeries,
    productDescription: $productDescription,
    problemDescription: $problemDescription,
    resolveTerm: $resolveTerm,
    isInWarranty: $isInWarranty
  }) {
    editServiceWork {
      response
    }
  }
}  
`;

export const PRINT_SERVICE_PAPER = gql`
mutation PrintServicePaper($id: String!) {
  createPrintServicePaper(input: {
    workId: $id
  }) {
    printServicePaper {
      response
    }
  }
}
`

export const PRINT_SERVICE_ESTIMATE = gql`
mutation PrintServiceEstimate($id: String!) {
  createPrintServiceEstimate(input: {
    workId: $id
  }) {
    printServiceEstimate {
      response
    }
  }
}
`;

export const SERVICE_WORK_REPORT_LIST = gql`
query GetServiceWorkList($first: Int, $after: String, $start: String, $end: String){
  services(first: $first, after: $after, filter_start: $start, filter_end: $end) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        id
        _id
        code
        clientName
        clientPhone
        createdAt
        statusLabel
        statusStyle
        productName
        resolveTermDate
        resolveTermDateString
        price
        priceModel {
          totalPrice
          price
          tax
          productOutcomes
          profit
        }
        createdBy {
            name
        }
      }
    }
  },
  reportWorkReport(start: $start, end: $end) {
    clientPaymentSum
    investmentSum
    profitSum
  }
}
`;

export const PRINT_SERVICE_REPORT = gql`
mutation PrintServiceReport($start: String, $end: String) {
  createPrintServiceReport(input: {
    start: $start,
    end: $end
  }) {
    printServiceReport {
      response
    }
  }
}
`;
