<template>
  <div>
    <base-page-heading title="Editare lucrare" v-bind:subtitle="'Editare si reconfigurare lucrare #'+getCode(work.code)">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Lucrari</b-breadcrumb-item>
          <b-breadcrumb-item active v-bind:text="'Editare lucrare #'+(loaded ? getCode(work.code) : '')"></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-row class="row-deck">
              <b-col md="10" class="mb-2">
                <b-button size="md" type="submit" variant="primary" @click="backToWork">
                  <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lucrare #{{getCode(work.code)}}</small>
                </b-button>
              </b-col>
            </b-row>
            <hr/>
            <b-form @submit.stop.prevent="onEditWorkSubmit">
              <b-row>
                <b-col xl="12">
                  <base-block title="Introduce datele lucrarii" header-bg content-full>
                    <div class="font-size-sm push">
                      <div class="row">
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="clientName">
                            <b-form-input size="lg" class="form-control-alt" id="clientName" name="clientName" placeholder="Nume client" v-model="$v.editWorkForm.clientName.$model" :state="!$v.editWorkForm.clientName.$error && (!errors.editWorkForm.clientName) && null" aria-describedby="clientName-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.clientName.required" id="clientName-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.clientName.minLength" id="clientName-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.clientName" v-text="errors.editWorkForm.clientName" id="clientName-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="clientPhone">
                            <b-form-input size="lg" class="form-control-alt" id="clientPhone" name="clientPhone" placeholder="Telefon" v-model="$v.editWorkForm.clientPhone.$model" :state="!$v.editWorkForm.clientPhone.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="clientPhone-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.clientPhone.required" id="clientPhone-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.clientPhone.minLength" id="clientPhone-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.clientPhone" v-text="errors.editWorkForm.clientPhone" id="clientPhone-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="address">
                            <b-form-input size="lg" class="form-control-alt" id="address" name="address" placeholder="Adresa (optional)" v-model="$v.editWorkForm.address.$model" :state="!$v.editWorkForm.address.$error && (!errors.editWorkForm.address) && null" aria-describedby="address-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="email">
                            <b-form-input size="lg" class="form-control-alt" id="email" name="email" placeholder="Email (optional)" v-model="$v.editWorkForm.email.$model" :state="!$v.editWorkForm.email.$error && (!errors.editWorkForm.email) && null" aria-describedby="email-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="productName">
                            <b-form-input size="lg" class="form-control-alt" id="productName" name="productName" placeholder="Denumire echipament" v-model="$v.editWorkForm.productName.$model" :state="!$v.editWorkForm.productName.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="productName-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productName.required" id="productName-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productName.minLength" id="productName-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.productName" v-text="errors.editWorkForm.productName" id="productName-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="productSeries">
                            <b-form-input size="lg" class="form-control-alt" id="productSeries" name="productSeries" placeholder="Serie echipament" v-model="$v.editWorkForm.productSeries.$model" :state="!$v.editWorkForm.productSeries.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="productSeries-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productSeries.required" id="productSeries-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productSeries.minLength" id="productSeries-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.productSeries" v-text="errors.editWorkForm.productSeries" id="productSeries-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-6">
                          <b-form-group class="form-group" label-for="productDescription">
                            <b-form-textarea size="lg" class="form-control-alt" id="productDescription" name="productDescription" placeholder="Detalii echipament" v-model="$v.editWorkForm.productDescription.$model" :state="!$v.editWorkForm.productDescription.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="productDescription-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-textarea>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productDescription.required" id="productDescription-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.productDescription.minLength" id="productDescription-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.productDescription" v-text="errors.editWorkForm.productDescription" id="productDescription-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-6">
                          <b-form-group class="form-group" label-for="problemDescription">
                            <b-form-textarea size="lg" class="form-control-alt" id="problemDescription" name="problemDescription" placeholder="Descrierea problemei" v-model="$v.editWorkForm.problemDescription.$model" :state="!$v.editWorkForm.problemDescription.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="problemDescription-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></b-form-textarea>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.problemDescription.required" id="problemDescription-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.problemDescription.minLength" id="problemDescription-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.problemDescription" v-text="errors.editWorkForm.problemDescription" id="problemDescription-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="resolveTermDate">
                            <flat-pickr size="lg" id="resolveTermDate" v-bind:class="{'is-invalid': $v.editWorkForm.resolveTermDate.$error || errors.editWorkForm.resolveTermDate}" class="form-control form-control-alt" placeholder="Termen de rezolvare" v-model="$v.editWorkForm.resolveTermDate.$model" :config="datepickerConfig" :state="!$v.editWorkForm.resolveTermDate.$error && (!errors.editWorkForm.clientPhone) && null" aria-describedby="resolveTermDate-feedback" @blur="removeServerErrors('editWorkForm')" @input="removeServerErrors('editWorkForm')"></flat-pickr>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.resolveTermDate.required" id="resolveTermDate-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.editWorkForm.resolveTermDate.minLength" id="resolveTermDate-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.editWorkForm.resolveTermDate" v-text="errors.editWorkForm.resolveTermDate" id="resolveTermDate-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4 mt-2">
                          <b-form-checkbox name="warranty" v-model="$v.editWorkForm.warranty.$model" switch stacked>
                            Echipamentul este in garantie
                          </b-form-checkbox>
                        </div>
                        <div class="col-sm-2 offset-sm-2 mt-2">
                            <b-button type="submit" variant="primary" block>
                              <i class="fa fa-fw fa-pencil-alt mr-1"></i> Editare
                            </b-button>
                        </div>
                      </div>
                    </div>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import {fleshErrorMessage, handleFormValidation} from "@/error/server-error";
import {backTo} from "../../../constants/helpers/common-helper";
import {EDIT_SERVICE_WORK, SERVICE_WORK_DETAIL} from "../../../constants/service-model-graphql";
import {getCode} from "../../../constants/helpers/service-helper";

export default {
  name: "ServiceWorkEdit",
  components: {flatPickr},
  mixins: [validationMixin],
  data () {
    return {
      workId: atob(this.$route.params.id),
      work: {},
      loaded: false,
      datepickerConfig: {},
      editWorkForm: {
        clientName: null,
        clientPhone: null,
        address: null,
        email: null,
        productName: null,
        productSeries: null,
        productDescription: null,
        problemDescription: null,
        resolveTermDate: null,
        warranty: null,
      },
      errors: {
        editWorkForm: {},
      },
    }
  },
  validations: {
    editWorkForm: {
      clientName: {
        required,
        minLength: minLength(3)
      },
      clientPhone: {
        required,
        minLength: minLength(3)
      },
      address: {},
      email: {},
      productName: {
        required,
        minLength: minLength(3)
      },
      productSeries: {
        required,
        minLength: minLength(3)
      },
      productDescription: {
        required,
        minLength: minLength(3)
      },
      problemDescription: {
        required,
        minLength: minLength(3)
      },
      resolveTermDate: {
        required,
        minLength: minLength(3)
      },
      warranty: {},
    }
  },
  methods: {
    backToWork() {
      backTo(this.$router, '/service/detail/'+this.$route.params.id);
    },
    getCode: getCode,
    onEditWorkSubmit() {
      this.errors.editWorkForm = {};
      this.$v.editWorkForm.$touch()
      if (this.$v.editWorkForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: EDIT_SERVICE_WORK,
        variables: {
          'id': this.work._id,
          'clientName': this.$v.editWorkForm.clientName.$model,
          'clientPhone': this.$v.editWorkForm.clientPhone.$model,
          'clientAddress': this.$v.editWorkForm.address.$model,
          'clientEmail': this.$v.editWorkForm.email.$model,
          'productName': this.$v.editWorkForm.productName.$model,
          'productSeries': this.$v.editWorkForm.productSeries.$model,
          'productDescription': this.$v.editWorkForm.productDescription.$model,
          'problemDescription': this.$v.editWorkForm.problemDescription.$model,
          'resolveTerm': this.$v.editWorkForm.resolveTermDate.$model,
          'isInWarranty': this.$v.editWorkForm.warranty.$model,
        }
      }).then(() => {
        this.flashMessage.info({message: "Lucrare #"+getCode(this.work.code)+" editat cu succes!"});
        this.$router
            .push('/service/list')
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.editWorkForm;
        this.errors.editWorkForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    }
  },
  apollo: {
    service: {
      query: SERVICE_WORK_DETAIL,
      fetchPolicy: "no-cache",
      variables() {
        return {
          id: this.workId
        }
      },
      result(result) {
        this.work = result.data.service;
        this.editWorkForm.clientName = this.work.clientName;
        this.editWorkForm.clientPhone = this.work.clientPhone;
        this.editWorkForm.address = this.work.clientAddress;
        this.editWorkForm.email = this.work.clientEmail;
        this.editWorkForm.productName = this.work.productName;
        this.editWorkForm.productSeries = this.work.productSeries;
        this.editWorkForm.productDescription = this.work.productDescription;
        this.editWorkForm.problemDescription = this.work.problemDescription;
        this.editWorkForm.resolveTermDate = this.work.resolveTermDate;
        this.editWorkForm.warranty = this.work.warranty;
        this.loaded = true;
      }
    }
  },

}
</script>
